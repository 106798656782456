import {
  // GROUND_HEIGHT,
  OFFSET_GROUND,
  PLAYER_GRAVITY,
  PLAYER_JUMP_SPEED,
  WALK_TIMER,
  PLAYER_STAND_SRC,
  PLAYER_RUN1_SRC,
  PLAYER_RUN2_SRC,
  PLAYER_JUMP_SRC,
  PLAYER_RUN3_SRC,
  PLAYER_RUN4_SRC
} from '../config';

export default class Player {
  // animation for running
  private WALK_ANIMATION_TIMER: number = WALK_TIMER;
  private walkAnimationTimer: number = this.WALK_ANIMATION_TIMER;
  private dinoRunImages: HTMLImageElement[] = [];

  private jumpPressed: boolean = false;
  private jumpInProgress: boolean = false;
  private falling: boolean = false;
  private JUMP_SPEED: number = PLAYER_JUMP_SPEED;
  private GRAVITY: number = PLAYER_GRAVITY;

  private ctx: CanvasRenderingContext2D;
  private canvas: HTMLCanvasElement;
  private width: number;
  private height: number;
  private minJumpHeight: number;
  private maxJumpHeight: number;
  private scaleRatio: number;

  private x: number;
  private y: number;
  private yStandingPosition: number;

  private jumpingStillImage: HTMLImageElement;
  private standingStillImage: HTMLImageElement;
  private image: HTMLImageElement;
  private jumpStartedAt: number = 0;
  public lastJumpAt: number = 0;

  constructor(
    ctx: CanvasRenderingContext2D,
    width: number,
    height: number,
    minJumpHeight: number,
    maxJumpHeight: number,
    scaleRatio: number
  ) {
    this.ctx = ctx;
    this.canvas = ctx.canvas;
    this.width = width;
    this.height = height;
    this.minJumpHeight = minJumpHeight;
    this.maxJumpHeight = maxJumpHeight;
    this.scaleRatio = scaleRatio;

    // position of player
    this.x = 10 * scaleRatio + 24;
    this.y = this.canvas.height - this.height - OFFSET_GROUND * scaleRatio;
    this.yStandingPosition = this.y;

    this.jumpingStillImage = new Image();
    this.jumpingStillImage.src = PLAYER_JUMP_SRC;
    this.image = this.jumpingStillImage;

    this.standingStillImage = new Image();
    this.standingStillImage.src = PLAYER_STAND_SRC;
    this.image = this.standingStillImage;

    const dinoRunImage1 = new Image();
    dinoRunImage1.src = PLAYER_RUN1_SRC;

    const dinoRunImage2 = new Image();
    dinoRunImage2.src = PLAYER_RUN2_SRC;

    const dinoRunImage3 = new Image();
    dinoRunImage3.src = PLAYER_RUN3_SRC;

    const dinoRunImage4 = new Image();
    dinoRunImage4.src = PLAYER_RUN4_SRC;

    this.dinoRunImages.push(dinoRunImage1);
    this.dinoRunImages.push(dinoRunImage2);
    this.dinoRunImages.push(dinoRunImage3);
    this.dinoRunImages.push(dinoRunImage4);

    // keyboard
    window.removeEventListener('keydown', this.keydown);
    window.removeEventListener('keyup', this.keyup);

    window.addEventListener('keydown', this.keydown);
    window.addEventListener('keyup', this.keyup);

    // touch
    this.canvas.removeEventListener('touchstart', this.touchstart);
    this.canvas.removeEventListener('touchend', this.touchend);

    this.canvas.addEventListener('touchstart', this.touchstart);
    this.canvas.addEventListener('touchend', this.touchend);

    // click on canvas
    this.canvas.removeEventListener('mousedown', this.touchstart);
    this.canvas.removeEventListener('mouseup', this.touchend);

    this.canvas.addEventListener('mousedown', this.touchstart);
    this.canvas.addEventListener('mouseup', this.touchend);
  }

  private touchstart = (): void => {
    this.jumpPressed = true;
    this.jumpStartedAt = new Date().getTime();
    this.lastJumpAt = new Date().getTime();
  };

  private touchend = (): void => {
    this.jumpPressed = false;
  };

  private keydown = (event: KeyboardEvent): void => {
    if (event.code === 'Space') {
      this.jumpPressed = true;
      this.jumpStartedAt = new Date().getTime();
      this.lastJumpAt = new Date().getTime();
    }
  };

  private keyup = (event: KeyboardEvent): void => {
    if (event.code === 'Space') {
      this.jumpPressed = false;
    }
  };

  update(gameSpeed: number, frameTimeDelta: number): void {
    this.run(gameSpeed, frameTimeDelta);

    if (this.jumpInProgress) {
      this.image = this.jumpingStillImage;
    }

    this.jump(frameTimeDelta);
  }

  private jump(frameTimeDelta: number): void {
    if (new Date().getTime() - this.jumpStartedAt > 600) this.jumpPressed = false;
    if (this.jumpPressed) {
      this.jumpInProgress = true;
    }

    if (this.jumpInProgress && !this.falling) {
      if (
        this.y > this.canvas.height - this.minJumpHeight - OFFSET_GROUND * this.scaleRatio ||
        (this.y > this.canvas.height - this.maxJumpHeight - OFFSET_GROUND * this.scaleRatio && this.jumpPressed)
      ) {
        this.y -= this.JUMP_SPEED * frameTimeDelta * this.scaleRatio;
      } else {
        this.falling = true;
      }
    } else {
      if (this.y < this.yStandingPosition) {
        this.y += this.GRAVITY * frameTimeDelta * this.scaleRatio;
        // Prevent the player from going below the ground level
        if (this.y >= this.yStandingPosition) {
          this.y = this.yStandingPosition;
          this.falling = false;
          this.jumpInProgress = false;
        }
      } else {
        this.falling = false;
        this.jumpInProgress = false;
      }
    }
  }

  private run(gameSpeed: number, frameTimeDelta: number): void {
    if (this.walkAnimationTimer <= 0) {
      // Get the current index of the image
      const currentIndex = this.dinoRunImages.indexOf(this.image);

      // Calculate the next index (loop back to 0 after the last image)
      const nextIndex = (currentIndex + 1) % this.dinoRunImages.length;

      // Set the next image
      this.image = this.dinoRunImages[nextIndex];

      // Reset the animation timer
      this.walkAnimationTimer = this.WALK_ANIMATION_TIMER;
    }

    // Decrease the timer based on frame time and game speed
    this.walkAnimationTimer -= frameTimeDelta * gameSpeed;
  }

  draw(): void {
    this.ctx.drawImage(this.image, this.x, this.y, this.width, this.height);
  }
}
