import http from '@/utilities/http.utils';

export class UserRepository {
  static getUserProfile(): Promise<any> {
    return http.get(`/api/v1/game/user-data`);
  }
  static checkAccountExist(initData: string): Promise<any> {
    return http.post(`/api/auth/telegram/check/exist`, {
      initData
    });
  }
  static checkAccountInfoFirstLogin(initData: string): Promise<any> {
    return http.post(`/api/auth/telegram/check/account`, {
      initData,
      chatId: '7479580172'
    });
  }

  static loginWithTelegram(initData: string, point?: string, referralCode?: string): Promise<any> {
    return http.post(`/api/auth/telegram/login`, {
      initData,
      ...(point && { point: point }),
      ...(referralCode && { referralCode: referralCode })
    });
  }

  static getAllBoosts(): Promise<any> {
    return http.get(`/api/v1/game/all-boosts`);
  }

  static upgradeBoost(boost_type: string): Promise<any> {
    return http.post(`/api/v1/game/upgrade-boost`, {
      boost_type
    });
  }

  static startFarming(): Promise<any> {
    return http.post(`/api/v1/game/start-farming`);
  }

  static estimateClaim(): Promise<any> {
    return http.get(`/api/v1/game/estimate-claim`);
  }

  static claim(): Promise<any> {
    return http.post(`/api/v1/game/claim`);
  }

  static getReferral(): Promise<any> {
    return http.get(`/api/v1/referral`);
  }

  static getReferralFrom(): Promise<any> {
    return http.get(`/api/v1/referral/referrer`);
  }

  static submitReferral(code: string): Promise<any> {
    return http.post(`/api/v1/referral`, {
      code
    });
  }

  static getReferralLeaderboard(): Promise<any> {
    return http.get(`/api/v1/referral/leaderboard?start=0&limit=100`);
  }

  static getReferralHistory(): Promise<any> {
    return http.get(`/api/v1/referral/referred`);
  }

  static updateQuest(quest: string): Promise<any> {
    return http.post(`/api/v1/quest/sync`, {
      quest
    });
  }

  static getUserQuest(): Promise<any> {
    return http.get(`/api/v1/quest/quests`);
  }
  static getAllQuest(): Promise<any> {
    return http.get(`/api/v1/quest/quest`);
  }

  static getClaimableQuest(): Promise<any> {
    return http.get(`/api/v1/quest/claimable`);
  }

  static checkTelegramQuest(telegram_id: string, channel_id: string): Promise<any> {
    return http.post(`/api/v1/quest/telegram/check`, {
      telegram_id,
      channel_id
    });
  }

  static checkTelegramPublicQuest(initData: string): Promise<any> {
    return http.post(`/api/v1/public/quest/telegram/check`, {
      initData
    });
  }

  static getLeaderboard(start: number, limit: number): Promise<any> {
    return http.get(`/api/v1/game/leaderboard?start=${start}&limit=${limit}`);
  }
}
