import styled from 'styled-components';
import { isMobileBrowser } from '../../utils/detectDevice';

export const GameContainer = styled.canvas`
  * {
    /* Disable select action */
    user-select: none;
    touch-action: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }

  ${isMobileBrowser()
    ? `
    -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);`
    : `
  width: 100%;
  `}

  margin-top: 80px;
`;
