import { selectToken } from '@/redux';
import { UserRepository } from '@/repository/user.repository';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const leaderboardKeys = {
  all: ['LEADERBOARD'] as const,
  userRank: () => [...leaderboardKeys.all, 'USER_RANK'] as const,
  filter: (limit: number) => [...leaderboardKeys.all, { limit }]
};

const DEFAULT_LIMIT = 50;
export const useLeaderboard = () => {
  const [pagination, setPagination] = useState({
    page: 0,
    total: 0
  });
  const token = useSelector(selectToken);
  const query = useInfiniteQuery({
    queryKey: leaderboardKeys.filter(DEFAULT_LIMIT),
    queryFn: async ({ pageParam = 0 }) => {
      const response = await UserRepository.getLeaderboard(pageParam * DEFAULT_LIMIT, DEFAULT_LIMIT);
      setPagination({
        page: pageParam,
        total: response.total
      });
      return { response, pageParam };
    },
    initialPageParam: 0,
    refetchOnWindowFocus: false,
    getNextPageParam: (response) => response.pageParam + 1,
    enabled: !!token
  });
  console.log('query', query);
  const leaderboard = useMemo(
    () => (query.data ? query.data.pages.flatMap((page) => page.response.list) : []),
    [query.data]
  );
  const hasNextPage = leaderboard.length < pagination.total;

  const handleLoadmore = () => {
    if (hasNextPage) {
      query.fetchNextPage();
    }
  };

  return { ...query, leaderboard, pagination, hasNextPage, handleLoadmore, total: query?.data };
};
