import styled from 'styled-components';
export const Wrapper = styled.div`
  width: 100vw !important;
  min-height: 100svh;
  background-size: 100% 100%;
  padding: 24px;
  background: linear-gradient(180deg, #402957 0%, #10051c 100%);
  .leaderboard-list {
    max-height: calc(100svh - 210px);
    overflow: auto;
  }
`;

export const LoadmoreWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 12px 0;
  .loadmore-btn {
    span {
      color: #402957;
    }
  }
`;
