import { ROUTES } from '@/modules/games/among-us/src/routes';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
// import RankingIcon from '@/modules/games/among-us/src/assets/icons/ranking.png';
// import HistoryIcon from '@/modules/games/among-us/src/assets/icons/history.png';
import BannerImg from '@/modules/games/among-us/src/assets/images/banner.png';
import { useAvailableSession } from '@/modules/games/among-us/src/hooks/useGame';
import React, { useEffect, useState } from 'react';
import Button from '@/components/button/button';
import PlayCountdown from '../../games/components/PlayCountdown';
import Instruction from '../../games/components/Instruction';
import { isMobileBrowser } from '../../utils/detectDevice';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .heading {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }

  .main-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .play-count {
    display: flex;
    padding: 14px 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(8px);
    font-weight: 500;
  }

  .title {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px; /* 150% */
  }

  .desc {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .img-btn {
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(8px);
    width: 36px;
    height: 36px;
    cursor: pointer;
    padding: 8px;
  }
`;

const HomePage = () => {
  const navigate = useNavigate();

  const { data, isLoading } = useAvailableSession();
  const dailyTurn = data && !isLoading ? data?.availableDailySession : 0;
  const [showInstruction, setShowInstruction] = useState(false);

  useEffect(() => {
    window.Telegram.WebApp?.exitFullscreen?.();
    window.Telegram.WebApp?.unlockOrientation?.();
  }, []);

  const handlePlay = () => {
    if (localStorage.getItem('DISABLED_INSTRUCTION') === null && isMobileBrowser()) {
      setShowInstruction(true);
      return;
    }
    window.Telegram.WebApp?.requestFullscreen?.();
    window.Telegram.WebApp?.unlockOrientation?.();
    navigate(ROUTES.PLAYING);
  };
  return (
    <Wrapper>
      {/* <div className='heading'>
        <img src={RankingIcon} alt='ranking' className='img-btn' />
        <img src={HistoryIcon} alt='history' className='img-btn' />
      </div> */}

      <div className='main-body'>
        <div className='title'>Among Friends</div>
        <img src={BannerImg} alt='' />
        <div className='desc'>
          Among Friends is a mini-game on HyperLaunch, where you navigate a lunar rover across the obstacles. As you
          play, you earn Crystal, which are added directly to your balance, making it a fun and rewarding experience.
        </div>
        {/* <Button variant="outlined" size="large">
          How To Play
        </Button> */}
        <div className='play-count'>
          <div>Free Play</div>
          <div>{dailyTurn}</div>
        </div>
        <Button className='w-full' onClick={handlePlay} disabled={dailyTurn === 0}>
          PLAY NOW
        </Button>

        {dailyTurn === 0 && <PlayCountdown />}
      </div>

      <Instruction
        visible={showInstruction}
        isDisplayCheckbox={true}
        isDisplayImage={true}
        onPlayGame={() => {
          navigate(ROUTES.PLAYING);
        }}
        onClose={() => {
          setShowInstruction(false);
        }}
      />
    </Wrapper>
  );
};
export default HomePage;
